import {useEffect, useState} from 'react';
import './App.css';
import logo from './logo.png';

const defaultOptions = [
  'Donkey shoots himself in the foot',
  '308 ilvl Hunter (carry)',
  'GET CLEAVED',
  'Forgot to Lust',
  'Stood in Braindead Mechanic',
  'Ranged standing in BFE',
  'We\'ll be fine without lust',
  'What\'s a kick?',
  'I have engi rez',
  'No keybind for defensive',
  'Mana Disrespect',
  'Massive pull with no CC',
  'Thunderfucked',
  'Buttpull',
  'Immediate brez death',
  'Total disregard for affixes',
  'Never ending chain-death pull',
  'Tank thinks it\'s the MDI',
  'No 100% trash at end',
  'Meters linked in chat',
  'LOL lag',
  'Chat-related death',
  'REKT by avoidable damage',
  'Why would you release?'
];

const defaultBonus = 'Joined a PUG';

function EditList(props){
  const [optionTitles, setOptionTitles] = useState(props.options || defaultOptions);
  const [bonusTitle, setBonusTitle] = useState(props.bonus || defaultBonus);

  function updateOption(opt, idx){
    const opts = [...optionTitles];
    opts[idx] = opt;
    setOptionTitles(opts);
  }

  return (<div className='container'>
    <h1>Edit Card Titles</h1>
    
    <div className='row'>
      <div className='col'>
        <h3>Bonus Square</h3>
        <input type='text' className='form-control' value={bonusTitle} onChange={e => setBonusTitle(e.target.value)}/>    
      </div>
    </div>

    <div className='row mt-3'>
      <div className='col'>
        <h3>Other Squares</h3>
        {optionTitles.map((o,i) => <input key={i} type='text' className='form-control mb-3' value={o} onChange={e => updateOption(e.target.value, i)}/>)}
      </div>
    </div>

    <div className='row mt-4'>
      <div className='col'>
        <button className='btn btn-primary' onClick={e => props.save(bonusTitle, optionTitles)}>Save</button>
      </div>
    </div>
  </div>);
}

function App() {
  const [board, setBoard] = useState([]);
  const [options, setOptions] = useState([]);
  const [bonus, setBonus] = useState();
  const [isEditing, setIsEditing] = useState();

  function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  function create(){
    const savedOptions = localStorage.getItem('options');
    const savedBonus = localStorage.getItem('bonus');
    const newOptions = (savedOptions && JSON.parse(savedOptions)) || defaultOptions; 
    const newBonus = (savedBonus && JSON.parse(savedBonus)) || defaultBonus;
    const items = shuffle([...newOptions]);
    const newBoard = [];

    let i = 0;
    for (var y = 0; y < 5; y++){
      newBoard.push([]);
      for (var x = 0; x < 5; x++){
        if (y === 2 && x === 2){
          newBoard[y][x] = { name: newBonus, selected:false};
        }else{
          newBoard[y][x] = { name: items[i], selected:false};
          i++;
        }
      }
    }
    setOptions(newOptions);
    setBonus(newBonus);
    setBoard(newBoard);
  }

  function calculateScore(){

  }

  function selectOption(event, option){
    //option.selected = true;
    //console.log('selected: ', event);
    event.target.classList.add('selected');
    calculateScore();
  }

  function reset(){
    if (window.confirm('Are you sure you want to reset the cards back to their original titles?') === true){
      save(defaultBonus, defaultOptions);
      window.setTimeout(() => window.location.reload(), 500);
    }
  }

  function edit(){
    setIsEditing(true);
  }

  function newCard(){
    window.location.reload();
  }

  function save(bonusTitle, optionTitles){
    localStorage.setItem('options', JSON.stringify(optionTitles));
    localStorage.setItem('bonus', JSON.stringify(bonusTitle));
    setIsEditing(false);
    window.location.reload();
  }

  useEffect(() =>{
    create();
  },[]);

  if (isEditing){
    return <EditList save={save} options={options} bonus={bonus}/>;
  }

  return (
    <>
    <div className="container">
      <div className="row mb-3">
        <div className="col text-center logo">
          <img src={logo} alt="Donkeystorm Gaming" className="circle"/>
          <span className="ms-2">Donkeystorm Gaming's</span>
        </div>
      </div>
      <div className="row">
        <div className="col h2">
          PUG Fail
        </div>
      </div>
      <div className="row">
        <div className='col'></div>
        <div className="col-2 h1 border">B</div>
        <div className="col-2 h1 border">I</div>
        <div className="col-2 h1 border">N</div>
        <div className="col-2 h1 border">G</div>
        <div className="col-2 h1 border">O</div>
        <div className='col'></div>
      </div>
      
      <div id='board'>
      {board.map((a, y) => {
            return (
              <div key={y} className="row">
              <div className='col'></div>
                {a.map((o,x) => {
                  return (<div key={(y+x)} className={(y === 2 && x === 2 ? 'bonus col-2 square border' : 'col-2 square border')} onClick={e => selectOption(e,o)}>{o.name}</div>);
                })}
                <div className='col'></div>
              </div>
            )
          })}
        </div>
        
        <div className="row mt-3">
          <div className="col text-center">
            <button className="btn btn-primary m-2" onClick={e => edit()}>Edit Card Titles</button>
            <button className="btn btn-primary m-2" onClick={e => reset()}>Reset Card Titles To Original</button>
            <button className="btn btn-primary m-2" onClick={e => newCard()}>New Card</button>
          </div>
        </div>
    </div>
    {/* <table>
        <thead>
          <tr>
            <th colSpan="5">PUG Fail</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>B</th>
            <th>I</th>
            <th>N</th>
            <th>G</th>
            <th>O</th>
          </tr>
          {board.map((a, y) => {
            return (
              <tr key={y}>
                {a.map((o,x) => {
                  return (<td key={(y+x)} className={(y === 2 && x === 2 ? 'bonus' : '')} onClick={e => selectOption(e,o)}>{o.name}</td>);
                })}
              </tr>
            )
          })}
        </tbody>
      </table> */}
    </>
  );
}

export default App;
